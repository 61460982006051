import './class-card.scss';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from 'antd';
import {
  Discounts,
  GetDiscountsQuery,
  Terms,
  // useGetClassInformationQuery,
  useGetDiscountsQuery,
  useGetClassSimpleLazyQuery,
} from '@services/data-access';
import Pill, { PillType } from '@components/Pill/Pill';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  convertToNumber,
  formatCurrencyFullText,
  parseUTCToTime,
  sortByKey,
} from '@helpers';
import { ColumnType } from 'antd/lib/table';
import CurrencyInput from '@components/CurrencyInput/component';
import { CustomTable } from '@components/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Key } from 'antd/lib/table/interface';
import { ProgramCategory } from '@models/program';
import Deduction from '../deduction/Deduction';
import Deposit from '../deposit/Deposit';
import OtherPayments from '../other-payments/OtherPayments';
import DeductDeposit from '../deduct-deposit/DeductDeposit';
import { StatusCode, ClassStatus } from '@models';
import { usePrevious } from '@hooks';
import moment from 'moment';
import { apiService } from '@services/api';

export interface CardChangedValue {
  id: string;
  lessonStartDate: string;
  quantityAdditionalLesson: number;
  additionalDiscount: number;
  termIds: string[];
  discountIds: string[];
  otherPayments: { name: string; amount: number }[];
  deductions: { name: string; amount: number }[];
  deposits: { name: string; amount: number }[];
  deductedDeposit: { name: string; amount: number }[];
  booterClassIds?: string[];
}
export interface ClassCardProps {
  onDelete: (id: string) => void;
  studentId: string;
  issueDate: string;
  classSelect: any;
  companyId: string;
  paymentAdvice: any;
  onChange: (value: CardChangedValue) => void;
  disable?: boolean;
}

export interface LessonTerms extends Terms {
  lessons: any[];
  pricing: number;
}

const ClassCard: FunctionComponent<ClassCardProps> = ({
  onDelete,
  studentId,
  issueDate,
  classSelect,
  disable,
  onChange,
  ...props
}) => {
  //define variables form props
  const lessonStartDateSelected = classSelect.lessonStartDate ?? '';
  const quantityAdditionalLesson =
    classSelect?.responses?.additionalLesson?.quantity || 0;
  const pricing = classSelect?.responses?.additionalLesson?.pricing || 0;
  const totalAddition = classSelect?.responses?.additionalLesson?.total || 0;
  const classId = classSelect.id || '';
  const termIds = (
    classSelect?.termInformations ||
    classSelect?.terms ||
    []
  ).map((item: any) => item?.id || '');
  const total = classSelect?.responses?.total || 0;
  const totalDiscount = classSelect?.responses?.totalDiscount || 0;
  const discounts = classSelect?.discounts;
  const otherPaymentData = classSelect?.responses?.otherPayments;
  const deductedDepositData = classSelect?.responses?.deductedDeposit;
  const deductionData = classSelect?.responses?.deductions;
  const depositData = classSelect?.responses?.deposits;
  const booterClassIds = classSelect?.booterClassIds;
  const isDeductedDeposit = classSelect?.isDeductedDeposit;

  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>(termIds);
  const prevSelectedRowKeys = usePrevious(selectedRowKeys);
  const [lessonStartDates, setLessonStartDates] = useState<string[]>([]);
  const [classInformation, setClassInformation] = useState<{
    prettyText: string;
    type: PillType;
    terms?: any;
  }>({
    type: 'primary',
    prettyText: '',
    terms: [],
  });
  const [discountList, setDiscountList] = useState<any[]>([]);
  const [discountFilter, setDiscountFilter] = useState<any[]>([]);
  const [discountSelected, setDiscountSelected] = useState<any>({});

  const [data, setData] = useState<any>();
  const [deductions, setDeduction] = useState<any[]>(
    classSelect.deductions || [],
  );
  const [deposits, setDeposit] = useState<any[]>(classSelect.deposits || []);
  const [otherPayments, setOtherPayment] = useState<any[]>(
    classSelect.otherPayments || [],
  );
  const [deductedDeposit, setDeductedDeposit] = useState<any[]>(
    classSelect.deductedDeposit || [],
  );

  const columns: ColumnType<LessonTerms>[] = [
    {
      title: 'Term',
      align: 'left',
      dataIndex: 'name',
      width: '20%',
      render: (text: string, record: LessonTerms, index: number) => (
        <span>Term {text}</span>
      ),
    },
    {
      title: 'Remaining',
      align: 'right',
      width: '40%',
      render: (text: string, record: LessonTerms, index: number) => {
        const dateSelectedIndex =
          record?.lessons?.findIndex(
            lesson => lesson === form.getFieldValue('lessonDate'),
          ) || -1;
        const restLessonInTerms =
          dateSelectedIndex > -1 && record.lessons
            ? record.lessons.length - dateSelectedIndex
            : record?.lessons?.length || '';
        return (
          <span>
            {restLessonInTerms} x {Number(record.pricing ?? 0)}{' '}
          </span>
        );
      },
    },
    {
      title: 'Total(S$)',
      align: 'right',
      render: (text: string, record: LessonTerms, index: number) => {
        const dateSelectedIndex =
          record?.lessons?.findIndex(
            lesson => lesson === form.getFieldValue('lessonDate'),
          ) || -1;
        const restLessonInTerms: number =
          dateSelectedIndex > -1 && record?.lessons
            ? record.lessons.length - dateSelectedIndex
            : record?.lessons?.length || 0;
        return (
          <span>
            {formatCurrencyFullText(
              (restLessonInTerms * (record?.pricing || 0)).toString(),
            )}
          </span>
        );
      },
    },
  ];

  async function getClassFee(opts: any) {
    try {
      const { studentId, classId, lessonStartDate, resetTerm } = opts || {};
      let urlToFetch = `/classes/class-free?studentId=${studentId}&classId=${classId}`;
      if (lessonStartDate) {
        urlToFetch = `${urlToFetch}&lessonStartDate=${lessonStartDate}`;
      }
      const { data: response } = await apiService.get(urlToFetch);
      const { data, success } = response || {};
      if (success) {
        setData(data);
        let type: PillType = 'primary';
        let prettyText = '';
        switch (data?.classStatus as ClassStatus) {
          case 'closed':
            type = 'brown';
            prettyText = 'Closed Class';
            break;

          case 'new':
            type = 'info';
            prettyText = 'New Class';

            break;
          case 'open':
            type = 'success';
            prettyText = 'Open Class';
        }
        if (!resetTerm) {
          setClassInformation({
            prettyText,
            type,
            terms: (data?.termInformations || data?.terms) as any[],
          });
        }

        form.setFieldsValue({
          additionLesson: quantityAdditionalLesson || 0,
          classPeriod: `${parseUTCToTime(
            data?.period?.startDate,
            'DD/MM/YYYY',
          )} - ${parseUTCToTime(data?.period?.endDate, 'DD/MM/YYYY')}`,
          booterClassIds: booterClassIds || [],
        });

        let lessonStartDatesTemp: string[] = [];

        const termSelected =
          (data?.termInformations ?? data?.terms ?? []).filter(term =>
            selectedRowKeys?.includes(term?.id ?? ''),
          ) ?? [];

        // eslint-disable-next-line no-unused-expressions
        (data?.termInformations ?? data?.terms)?.forEach(
          (item: LessonTerms) =>
            (lessonStartDatesTemp = lessonStartDatesTemp.concat(
              item?.lessons ?? [],
            )),
        );

        const selectDate = termSelected.reduce((acc: any, cur: any) => {
          return [...acc, cur?.lessons].flat(Infinity);
        }, []);

        if (!lessonStartDates.length) {
          setLessonStartDates(lessonStartDatesTemp);
          form.setFieldsValue({
            lessonDate:
              classSelect?.lessonStartDate ||
              data?.lessonStartDate ||
              selectDate[0],
          });
        }

        // if (data) {
        //   onChange({
        //     id: classId,
        //     lessonStartDate: moment(new Date()).format('YYYY-MM-DD'),
        //     quantityAdditionalLesson: 0,
        //     termIds: [],
        //     discountIds: [],
        //     additionalDiscount: 0,
        //   });
        // }
      }
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      }
    }
  }

  const classIdMemo = React.useMemo(() => classId, [classId]);

  useEffect(() => {
    if (classIdMemo && studentId) {
      getClassFee({ classId, studentId });
    }
  }, [classIdMemo, studentId]);

  useEffect(() => {
    form.setFieldsValue({
      booterClassIds: booterClassIds || [],
    });
  }, [booterClassIds]);

  useEffect(() => {
    if (
      [ProgramCategory.Special, ProgramCategory.Others].includes(
        data?.classType,
      )
    ) {
      onSelectChange(['']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useGetDiscountsQuery({
    variables: {
      filter: {
        status: { _neq: StatusCode.Deleted },
        companyId: { _eq: props.companyId },
      },
    },
    onCompleted: (result: GetDiscountsQuery) =>
      setDiscountList(result?.discounts ?? []),
    onError: () => {},
  });

  const [getDoubleClassList, { data: doubleClass }] =
    useGetClassSimpleLazyQuery({
      fetchPolicy: 'no-cache',
      onError: () => {},
    });

  useEffect(() => {
    if (!!classSelect) {
      let year: string = '';
      const isYearMatched = classSelect?.name?.match(/\b\d{4}\b/);
      if (isYearMatched) {
        year = isYearMatched[0];
      }

      getDoubleClassList({
        variables: {
          where: {
            year: { _eq: year },
            classStatus: { _neq: 'closed' },
            companyId: {
              _eq: classSelect?.program?.companyId || props.companyId,
            },
            category: { _eq: ProgramCategory.DoubleLesson },
            ...((classSelect?.program?.curriculumIds?.length ||
              classSelect?.responses?.curriculums?.length ||
              classSelect?.program?.levelIds?.length ||
              classSelect?.responses?.levels?.length ||
              classSelect?.program?.subjectId ||
              classSelect?.responses?.subject?.id) && {
              program: {
                ...((classSelect?.program?.curriculumIds?.length ||
                  classSelect?.responses?.curriculums?.length) && {
                  curriculumIds: {
                    _contains:
                      classSelect?.program?.curriculumIds ||
                      classSelect?.responses?.curriculums.map(c => c.id),
                  },
                  ...((classSelect?.program?.levelIds?.length ||
                    classSelect?.responses?.levels?.length) && {
                    levelIds: {
                      _contains:
                        classSelect?.program?.levelIds ||
                        classSelect?.responses?.levels.map(l => l.id),
                    },
                  }),
                  ...((classSelect?.program?.subjectId ||
                    classSelect?.responses?.subject?.id) && {
                    subjectId: {
                      _eq:
                        classSelect?.program?.subjectId ||
                        classSelect?.responses?.subject?.id,
                    },
                  }),
                }),
              },
            }),
          },
        },
      });
    }
  }, [classSelect]);

  // useEffect(() => {
  //   if (classSelect.programTypeSpecialId && classIdMemo) {
  //     onChange({
  //       id: classId,
  //       lessonStartDate: moment(lessonStartDates?.[0]).format(
  //         'YYYY-MM-DD',
  //       ) as string,
  //       quantityAdditionalLesson: 0,
  //       termIds: [] as string[],
  //       discountIds: [] as string[],
  //       additionalDiscount: 0,
  //       booterClassIds: [],
  //       deductions,
  //       deposits,
  //       otherPayments,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [classIdMemo, lessonStartDates]);

  useEffect(() => {
    form.setFieldsValue({
      lessonDate: lessonStartDateSelected?.includes('T00:00:00.000Z')
        ? lessonStartDateSelected
        : lessonStartDateSelected + 'T00:00:00.000Z',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonStartDateSelected]);

  useEffect(() => {
    form.setFieldsValue({
      additionLesson: quantityAdditionalLesson,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityAdditionalLesson]);

  useEffect(() => {
    if (issueDate && discountList) {
      const newDiscountList = discountList.filter((item: any) => {
        const { startDate, endDate } = item;
        return (
          moment(issueDate).isAfter(moment(startDate)) &&
          moment(issueDate).isBefore(moment(endDate))
        );
      });
      form.setFieldsValue({
        content:
          discounts?.map((discount: Discounts) => ({
            id: discount.id,
            amount: discount.amount,
          })) ?? [],
        additionalDiscount: classSelect?.additionalDiscount || 0,
      });

      setDiscountFilter(newDiscountList);
      // if (selectedRowKeys.length) {
      //   onChange({
      //     id: classId,
      //     lessonStartDate: moment(classSelect?.lessonStartDate).format(
      //       'YYYY-MM-DD',
      //     ),
      //     quantityAdditionalLesson: form.getFieldValue('additionLesson'),
      //     termIds: termIds as string[],
      //     discountIds: discounts?.map((discount: any) => discount.id),
      //     additionalDiscount: classSelect?.additionalDiscount || 0,
      //   });
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueDate, discountList]);

  useEffect(() => {
    onFieldChanged({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductions, deposits, otherPayments, deductedDeposit]);

  const onStartDateChanged = async (date: string) => {
    let form = {
      classId,
      studentId,
      lessonStartDate: moment(date).format('YYYY-MM-DD'),
      resetTerm: true,
    };
    await getClassFee(form);
    setSelectedRowKeys([]);
  };

  const onSelectChange = async (selectedRowKeys: Key[]) => {
    setSelectedRowKeys(selectedRowKeys);

    const termSelected =
      (data?.termInformations ?? data?.terms ?? []).filter(term =>
        selectedRowKeys?.includes(term?.id ?? ''),
      ) ?? [];

    const selectedDateList = termSelected.reduce((acc: any, cur: any) => {
      return [...acc, cur?.lessons].flat(Infinity);
    }, []);

    let startDate;

    if (!selectedRowKeys.length) {
      startDate = lessonStartDates[0];
      form.setFieldsValue({
        lessonDate: lessonStartDates[0],
      });
    } else {
      startDate = selectedDateList[0];
      form.setFieldsValue({
        lessonDate: selectedDateList[0],
      });
    }

    onChange({
      id: classId,
      lessonStartDate: moment(form.getFieldValue('lessonDate')).format(
        'YYYY-MM-DD',
      ),
      quantityAdditionalLesson: form.getFieldValue('additionLesson'),
      termIds: selectedRowKeys as string[],
      discountIds:
        form
          .getFieldValue('content')
          ?.map((item: any) => item?.id)
          ?.filter((item: any) => item) ?? [],
      additionalDiscount: convertToNumber(
        form.getFieldValue('additionalDiscount')
          ? form.getFieldValue('additionalDiscount').toString()
          : '',
      ),
      booterClassIds: form.getFieldValue('booterClassIds') || [],
      deductions,
      deposits,
      otherPayments,
      deductedDeposit,
    });
    if (
      ![ProgramCategory.Special, ProgramCategory.Others].includes(
        data.classType,
      )
    ) {
      let formData = {
        classId,
        studentId,
        lessonStartDate: moment(startDate).format('YYYY-MM-DD'),
        resetTerm: true,
      };
      await getClassFee(formData);
    }
  };

  const deleteClass = () => onDelete(classId);

  // const debounceFn = React.useCallback(debounce(onChange, 300), []);
  const onFieldChanged = (value: any) => {
    if (!!selectedRowKeys?.length) {
      onChange({
        id: classId,
        lessonStartDate: moment(form.getFieldValue('lessonDate')).format(
          'YYYY-MM-DD',
        ),
        quantityAdditionalLesson: form.getFieldValue('additionLesson') || 0,
        termIds: selectedRowKeys as string[],
        discountIds:
          form
            .getFieldValue('content')
            ?.map((item: any) => item?.id)
            ?.filter((item: any) => item) ?? [],
        additionalDiscount: convertToNumber(
          form.getFieldValue('additionalDiscount')
            ? form.getFieldValue('additionalDiscount').toString()
            : '',
        ),
        booterClassIds: form.getFieldValue('booterClassIds') || [],
        deductions,
        deposits,
        otherPayments,
        deductedDeposit,
      });
    }
  };

  const _handleSelectDiscount = (value: string, index: number) => {
    const discounts = form.getFieldValue('content');
    const selected = discountList.find(item => item.id === value);
    discounts[index].amount = selected.amount;
    form.setFieldsValue({
      content: discounts,
    });

    setDiscountSelected({ ...discountSelected, [index]: selected });
    if ([ProgramCategory.Others].includes(data.classType)) {
      onSelectChange(['']);
    }
  };

  const _handleRemoveDiscount = (index: number, cb: (idx: number) => void) => {
    setDiscountSelected({ ...discountSelected, [index]: undefined });
    cb(index);
  };

  const filterDiscount = (index: number) => {
    const discountsValue = form.getFieldValue(['content']);
    const currentDiscount = discountsValue ? discountsValue[index] : {};

    return (
      discountFilter?.filter(e => {
        if (currentDiscount && e.id === currentDiscount.id) {
          return true;
        }
        if (discountsValue.map((c: any) => c && c.id)?.includes(e.id)) {
          return false;
        }

        return true;
      }) || []
    );
  };

  const termContainLessonDate = classInformation?.terms?.find((term: any) =>
    term.lessons?.some(
      (lesson: any) => lesson === form.getFieldValue('lessonDate'),
    ),
  );
  let isDisabled = disable || !selectedRowKeys.length;
  let disabledButton = !selectedRowKeys.length;

  return (
    <Form
      form={form}
      layout="vertical"
      className="create-payment-advice-class-card"
      onValuesChange={onFieldChanged}
    >
      <Row>
        <Col span={12} className="payment-advice-block border-right">
          <Pill
            type={classInformation.type}
            text={classInformation.prettyText}
          />
        </Col>
        <Col span={12} className="payment-advice-block">
          {!disable && (
            <Button
              onClick={deleteClass}
              icon={
                <Icon viewBox={'0, 0, 1024, 1024'}>
                  <FontAwesomeIcon icon={['far', 'trash-alt']} />
                </Icon>
              }
              disabled={disable}
              type="link"
              className="text-danger remove-card"
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={12} className="border-right">
          <span className="title">{data?.name}</span>
        </Col>
        <Col span={12}>
          <span className="title">Discount</span>
        </Col>
      </Row>

      <Row>
        <Col span={12} className="border-right">
          <div className="lesson-date">
            {data?.classType === ProgramCategory.Regular ? (
              <Form.Item
                name="lessonDate"
                rules={[
                  {
                    required: true,
                    message: 'Please input lesson start date!',
                  },
                ]}
                label="(*) Lesson Start Date"
              >
                <Select disabled={disable} onChange={onStartDateChanged}>
                  {lessonStartDates.map(lesson => (
                    <Select.Option key={lesson} value={lesson}>
                      {parseUTCToTime(lesson, 'DD/MM/YYYY')}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item name="classPeriod" label="Class Period">
                <Input disabled />
              </Form.Item>
            )}
          </div>

          {data?.classType === ProgramCategory.Regular && (
            <div className="payment-advice-block border-top">
              <CustomTable
                rowKey={record => record.id || ''}
                columns={columns}
                dataSource={classInformation.terms}
                size="middle"
                rowSelection={
                  !disable
                    ? {
                        selectedRowKeys,
                        onChange: onSelectChange,
                        getCheckboxProps: (record: any) => ({
                          disabled:
                            termContainLessonDate &&
                            Number(record?.name) <
                              Number(termContainLessonDate?.name),
                        }),
                      }
                    : undefined
                }
                pagination={false}
              />
            </div>
          )}
        </Col>
        <Col span={12}>
          <Form.List name="content">
            {(fields, { add, remove }) => (
              <div className="discount__wrapper">
                <div className="discount__list">
                  {fields?.map((filed, index) => (
                    <Row key={index} className="discount__item">
                      <Col span={22}>
                        <Form.Item
                          label="(*) Discount Name"
                          rules={[
                            { required: true, message: 'Name is required.' },
                          ]}
                          name={[index, 'id']}
                        >
                          <Select
                            showSearch
                            placeholder="Select discount"
                            onChange={(value: string) =>
                              _handleSelectDiscount(value, index)
                            }
                            disabled={disable || !issueDate}
                            filterOption={(input, option) => {
                              return (
                                option?.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) > -1
                              );
                            }}
                          >
                            {filterDiscount(index).map(e => (
                              <Select.Option value={e.id} key={e.id}>
                                {e.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label="(*) Amount" name={[index, 'amount']}>
                          <CurrencyInput
                            disabled={true}
                            negative={true}
                            unit={discountSelected?.[index]?.unit}
                            placeholder="S$00.00"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        {!disable && (
                          <Button
                            type="link"
                            disabled={disable}
                            onClick={() => {
                              _handleRemoveDiscount(index, remove);
                              if (
                                [
                                  ProgramCategory.Special,
                                  ProgramCategory.Others,
                                ].includes(data.classType)
                              ) {
                                onSelectChange(['']);
                              }
                            }}
                            icon={
                              <Icon viewBox={'0, 0, 1024, 1024'}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} />
                              </Icon>
                            }
                            className="discount__remove"
                          />
                        )}
                      </Col>
                    </Row>
                  ))}
                </div>
                {!disable && (
                  <div
                    className={`payment-advice-block ${
                      fields.length ? 'border-top' : ''
                    }`}
                  >
                    <Button
                      type="primary"
                      ghost
                      className="discount__add"
                      htmlType="button"
                      onClick={() => add()}
                      disabled={disabledButton}
                      icon={
                        <Icon viewBox={'0, 0, 1024, 1024'}>
                          <FontAwesomeIcon icon={['fal', 'plus']} />
                        </Icon>
                      }
                    >
                      Add Discount
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Form.List>
        </Col>
      </Row>

      {data?.classType === ProgramCategory.Regular && (
        <Row>
          <Col
            span={12}
            className="payment-advice-block border-top border-right"
          >
            <Row align="middle" gutter={[10, 0]}>
              <Col span={10}>
                <Form.Item name="additionLesson" label="Additional Lesson">
                  <InputNumber
                    disabled={isDisabled}
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Row>
                  <Col span={12} className="text-ink text-right">
                    x{' '}
                    {pricing
                      ? Number(pricing)
                      : Number(data?.additionalLesson?.pricing)}
                  </Col>
                  <Col span={12} className="text-bold text-ink text-right">
                    {`${formatCurrencyFullText(
                      totalAddition || totalAddition === 0
                        ? totalAddition.toString()
                        : data?.additionalLesson?.total?.toString() || '0',
                    )}`}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12} className="payment-advice-block border-top">
            <Row align="middle" gutter={[10, 0]}>
              <Col span={24}>
                <Form.Item
                  name="additionalDiscount"
                  label="Additional Discount"
                >
                  <CurrencyInput
                    unit="S$"
                    placeholder="S$00.00"
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {data?.classType === ProgramCategory.Regular && (
        <Row>
          <Col
            span={24}
            className="payment-advice-block border-top border-right"
          >
            <Row justify="space-between">
              <p className="title" style={{ padding: 0 }}>
                Booster Lesson
              </p>
              <p className="title" style={{ padding: 0 }}>
                (Total:{' '}
                {formatCurrencyFullText(
                  (classSelect?.responses?.boosterClassesPrice || 0).toString(),
                )}
                )
              </p>
            </Row>
            <Form.Item name="booterClassIds" label="">
              <Select
                placeholder="Select Booster Lesson"
                mode="multiple"
                disabled={isDisabled}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {sortByKey(doubleClass?.classes, 'name')?.map((_c: any) => (
                  <Select.Option key={_c.id} value={_c.id} children={_c.name} />
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={24}
            className="payment-advice-block border-top border-right"
          >
            <OtherPayments
              onChange={_d => {
                setOtherPayment(_d.rows);
              }}
              otherPayments={otherPaymentData}
              disabled={disable}
              disabledButton={disabledButton}
            />
          </Col>
          <Col
            span={24}
            className="payment-advice-block border-top border-right"
          >
            <Deduction
              onChange={_d => setDeduction(_d.rows)}
              paymentAdvice={deductionData}
              disabled={disable}
              disabledButton={disabledButton}
            />
          </Col>
          <Col
            span={24}
            className="payment-advice-block border-top border-right"
          >
            <Deposit
              onChange={_d => {
                setDeposit(_d.rows);
              }}
              defaultAmt={props?.paymentAdvice?.depositSettings?.amount || 0}
              paymentAdvice={depositData}
              disabled={disable}
              disabledButton={disabledButton}
            />
          </Col>
          {!!isDeductedDeposit && (
            <Col
              span={24}
              className="payment-advice-block border-top border-right"
            >
              <DeductDeposit
                onChange={_d => {
                  setDeductedDeposit(_d.rows);
                }}
                deductedDeposit={deductedDepositData}
                disabled={disable}
                disabledButton={disabledButton}
              />
            </Col>
          )}
        </Row>
      )}
      {data?.classType === ProgramCategory.Special && (
        <Row>
          <Col
            span={24}
            className="payment-advice-block border-top border-right"
          >
            <Deduction
              onChange={_d => setDeduction(_d.rows)}
              paymentAdvice={deductionData}
              disabled={disable}
              disabledButton={disabledButton}
            />
          </Col>
          <Col
            span={24}
            className="payment-advice-block border-top border-right"
          >
            <OtherPayments
              onChange={_d => {
                setOtherPayment(_d.rows);
              }}
              otherPayments={otherPaymentData}
              disabled={disable}
              disabledButton={disabledButton}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12} className="payment-advice-block border-top border-right">
          <Row justify="end">
            <Col span={16}>
              <div className="text-ink text-bold text-right">Total Amount</div>
            </Col>
            <Col span={8}>
              <div className="text-ink text-bold text-right">
                {`${formatCurrencyFullText(
                  !selectedRowKeys?.length
                    ? '0'
                    : total
                    ? total.toString()
                    : data?.total?.toString() || '0',
                )}`}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12} className="payment-advice-block border-top">
          <Row justify="end">
            <Col span={16}>
              <div className="text-ink text-bold text-right">
                Total Discount Amount
              </div>
            </Col>
            <Col span={8}>
              <div className="text-ink text-bold text-right">
                {`${formatCurrencyFullText(
                  totalDiscount ? totalDiscount.toString() : '0',
                )}`}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default ClassCard;
